import React, { Component } from 'react'
import styles from './footer.module.css'
import moment from 'moment'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
// import { Twitter} from 'react-line-awesome'
// import { faTwitter, faPinterest, faInstagram, faFacebook } from '@fortawesome/free-solid-svg-icons'

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showHeader: true,
      isTop: true,
      showGPDR: true
    }
  }

  componentDidMount() {
    if(cookies.get('gpdr-okay') === 'true'){
      this.setState({
        showGPDR: false
      })
    }
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 20;
      if (isTop !== this.state.isTop) {
          this.setState({ isTop })
      }
    });
  }

  closeGPDR() {
    var oneYearFromNow = new Date()
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
    cookies.set('gpdr-okay', 'true', { expires: oneYearFromNow })
    this.setState({
      showGPDR: false
    })
  }

  render() {
    let year = moment().format('YYYY')
    let topClass = ' footer-down'
    if(this.state.isTop){
      topClass = ' footer-up'
    }
    let GPDRMessage = null
    if(this.state.showGPDR){
      GPDRMessage = <div className={styles['gpdr_message']}>
      <h3>This Website Uses Cookies</h3>
      <p>This website requires certain cookies to work and to analyze our
      traffic. By closing this message or continuing to use our site, you agree
      to the use of cookies. Visit our <a href="https://www.forms-surfaces.com/privacy-policy" rel="noreferrer" target="_blank">
      privacy policy</a> to learn more.</p><button className={styles['close_gpdr_message']}
      onClick={(e) => this.closeGPDR()}><span style={{display:'none'}}>close</span></button>
       </div>
    }
    return (
      <>
      <footer className={styles.footer + topClass}>
        <div className="row">
          <div className={`col-xs-6 ${styles[`footer__copyright`]}`}>&copy;{year} Forms+Surfaces</div>
          <div className={`col-xs-6 ${styles[`social__icons`]}`}>
            <a href="https://www.pinterest.com/FormsSurfaces/" target="_blank" rel="noopener noreferrer" title="Forms+Surfaces on Pinterest">
              <i className={styles['edsico'] + ' ' + styles['eds_pinterest_square']}></i>
            </a>
            <a href="https://www.instagram.com/forms_surfaces/" target="_blank" rel="noopener noreferrer" title="Forms+Surfaces on Instagram">
              <i className={styles['edsico'] + ' ' + styles['eds_instagram_square']}></i>
            </a>
            <a href="https://www.facebook.com/FormsandSurfaces/" target="_blank" rel="noopener noreferrer" title="Forms+Surfaces on Facebook">
              <i className={styles['edsico'] + ' ' + styles['eds_facebook_square']}></i>
            </a>
          </div>
        </div>
      </footer>
      {GPDRMessage}
      </>
    )
  }
}

export default(Footer)
