import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './error-message.module.css'

class ErrorMessage extends Component{
  constructor(props) {
    super(props)
    this.state = {
      visible: true
    }
  }

  componentDidMount(){

  }

  render(){
    let link = ''
    if(this.props.link){
      link = <Link to={this.props.link}>{this.props.linkMessage}</Link>
    }
    let closeButton = null
    if(this.props.closeAction){
      closeButton = <a className="button" href="/" onClick={(e)=>{this.props.closeAction(e)}}>Close</a>
    }
    if(this.state.visible){
      return(
        <div className={styles['errorMessageWrapper']}>
          <div className={styles['message']}>
            <i className="las la-exclamation-circle"></i>
            {this.props.message}
            {link}
            {closeButton}
          </div>
        </div>
      )
    }else{
      return(null)
    }
  }
}

export default ErrorMessage
