import axios from 'axios'
import { Auth } from 'aws-amplify'
const restPath = 'https://api.forms-surfaces.net/v1'
const restApiPath = 'https://api.forms-surfaces.net/v1'

export const getProfileImage = () => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.profileImage ? localStorage.profileImage : null
  } else {
    return null
  }
}

const userInfo = () => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .get(restPath + '/profile',
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(userInfo) {
              if(userInfo.data){
                if(userInfo.data.profileImage){
                  localStorage.profileImage = userInfo.data.profileImage.download
                }
                localStorage.gatsbyUser = JSON.stringify(userInfo.data)
                resolve(userInfo.data)
              }else{
                reject(new Error('no user returned'))
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const getUserInfo = () => {
  return userInfo()
}

export const returnUserInfo = () => {
  userInfo()
  .then(function(userInfo){
    return userInfo
  })
  .catch(function(err){
    return err
  })
}

export const updateProfileImage = (image) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .post(restApiPath + '/user-profile-image', {
              fileName: image.name,
              type: image.type
            },
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(fileData) {
              axios
                .put(fileData.data, image, {headers: {'Content-Type': image.type }})
                .then(function(data) {
                  resolve(data)
                })
                .catch(function(err) {
                  reject(err)
                })
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateProfile = (profile) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .post(restPath + '/user', profile,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(profileData) {
              resolve(profileData.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}
