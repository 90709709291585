import React from 'react'
import { Link } from 'gatsby'
import styles from './header.module.css'
import logo from './img/eds-logo.svg'
import logoWhite from './img/eds-logo-white.svg'
import fsicon from './img/fs-icon.svg'
import profileImageHolder from './img/profile-image.svg'
import profileImageHolderWhite from './img/profile-image-white.svg'
import logoutButtonImg from './img/logout-button.svg'
import logoutButtonImgWhite from './img/logout-button-white.svg'
import hamburgerImg from './img/hamburger.svg'
import hamburgerImgWhite from './img/hamburger-white.svg'
import { parseQuery } from '../../utils/query'
import { logout, getCurrentAuthUser } from "../../utils/auth"
import { globalHistory } from '@reach/router'
import { getProfileImage } from '../../utils/user'
import PopoutMenu from './popout-menu'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.updateLoggedStatus = this.updateLoggedStatus.bind(this)
    this.state = {
      showHeader: true,
      expandMenu: false,
      isLogged: false
    }
  }

  async updateLoggedStatus() {
   const username = await getCurrentAuthUser()
   let newLoggedStatus = false
   if (username) newLoggedStatus = true
   this.setState({ isLogged: newLoggedStatus })
  }

  componentDidMount() {
    this.updateLoggedStatus()
    if (globalHistory.location) {
      if (globalHistory.location.search) {
        var query = parseQuery(globalHistory.location.search)
        if (query.iframe) {
          this.setState({
            showHeader: false,
          })
        }
      }
    }
  }

  toggleMenu = (expand) => {
    this.setState({
      expandMenu: expand
    })
  }

  render() {
    if(this.state.showHeader){
      let profileImage = profileImageHolder
      let edsLogo = logo
      let hamburgerButton = hamburgerImg
      let transparentStyle = ''
      let logoutButtonImage = logoutButtonImg
      if(this.props.wrapperClass === 'home-page'){
        edsLogo = logoWhite
        profileImage = profileImageHolderWhite
        logoutButtonImage = logoutButtonImgWhite
        hamburgerButton = hamburgerImgWhite
        transparentStyle = styles.transparentStyle
      }
      let image = getProfileImage()
      if(image){
        profileImage = image
      }
      let logoutButton = <a
        href="/"
        className={styles[`header__link_icon`]}
        onClick={event => {
          event.preventDefault()
          window.location.assign('/profile')
        }}
      >
        <img alt="" src={logoutButtonImage}
        className={styles[`header__link_icon_img`]}
        />
      </a>

      if(this.state.isLogged){
        logoutButton = <a
          href="/"
          className={styles[`header__link_icon`]}
          onClick={event => {
            event.preventDefault()
            logout(function(){window.location.assign('/')})
          }}
        >
          <img alt="" src={logoutButtonImage}
          className={styles[`header__link_icon_img`]}
          />
        </a>
      }

      return (
        <header className={styles.header + ' ' + transparentStyle}>
          <div className={`${styles[`header__wrap`]} row ${transparentStyle}`}>
            <div className={`col-xs-6`}>
            <Link
              to="/"
              className={styles[`header__link`]}
            >
              <img src={edsLogo} alt="logo" className={styles[`header__logo`]} />
            </Link>
            </div>
            <nav role="main" className={`${styles[`header__nav`]} col-xs-6`}>
              <Link to="/profile"
                className={styles[`header__link_icon`]}>
                <img alt="" src={profileImage}
                className={styles[`header__profile_img`]}
                />
              </Link>
              {logoutButton}
              <a
                href="/"
                className={styles[`header__link_icon`]}
                onClick={event => {
                  event.preventDefault()
                  this.toggleMenu(true)
                }}
              >
                <img alt="" src={hamburgerButton}
                className={styles[`header__link_icon_img`]}
                />
              </a>
            </nav>
          </div>
          <PopoutMenu
          toggleMenu={this.toggleMenu}
          showMenu={this.state.expandMenu}
          isLogged={this.state.isLogged}
          />
        </header>
      )
    }else{
      return(
        <div></div>
      )
    }
  }
}

export default Header
