import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './header.module.css'
import { logout } from "../../utils/auth"

class PopoutMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }
  }

  componentDidMount() {

  }

  closeMenu = () => {
    this.props.toggleMenu(false)
  }

  render() {
    if(this.props.showMenu){
      let loginLogout = <button className={styles['logoutButton']}
        onClick={event => {
          event.preventDefault()
          window.location.assign('/profile')
        }}>
        <i className="las la-sign-out-alt"></i>LOGIN</button>

      if(this.props.isLogged){
        loginLogout = <button className={styles['logoutButton']}
          onClick={event => {
            event.preventDefault()
            logout(function(){window.location.assign('/')})
          }}>
          <i className="las la-sign-out-alt"></i>LOGOUT</button>
      }
      return (
        <div className={styles['popoutMenu']}>
          <button className={styles['popoutCloseButton']}
          onClick={(e) => this.closeMenu()}>
          <i className="las la-times"></i></button>
          <ul className={styles['linkList']}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/how-does-it-work">How Does It Work</Link></li>
            <li><Link to="/portfolio">Portfolio</Link></li>
            <li className={styles['noHover']}>Contact Us
            </li>
          </ul>
          <div className={styles['contactText']}>
            <h3>GENERAL</h3>
            <a href="mailto:info@elevatordesignstudio.com">
            info@elevatordesignstudio.com</a>
            <h3>SUPPORT</h3>
            <a href="mailto:support@elevatordesignstudio.com">
            support@elevatordesignstudio.com</a>
          </div>
          {loginLogout}
        </div>
      )
    }else{
      return(null)
    }
  }
}

export default(PopoutMenu)
