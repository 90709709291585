export const isBrowser = typeof window !== 'undefined';

export const checkSafari = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false
    } else {
      //only trigger for safari 13 and 14 versions
      if(ua.includes('version/14') || ua.includes('version/13') || ua.includes('version/12')){
        return true
      }
    }
  }else{
    return false
  }
}

export const getUserAgent = () => {
  return navigator.userAgent
}
