import React from 'react'
// import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import Amplify from 'aws-amplify'
import config from '../../config'
import Header from '../Header'
import Footer from '../Footer'
import ErrorMessage from '../ErrorMessage'
import { navigate } from '@reach/router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faCheckSquare,
  faCoffee,
  faEdit,
  faCopy,
  faStar,
  faFileInvoiceDollar,
  faCloudDownloadAlt,
  faSearch,
  faCheckCircle,
  faPlusCircle,
  faTrash } from '@fortawesome/free-solid-svg-icons'
import 'lightbox-react/style.css'
import 'line-awesome/dist/line-awesome/css/line-awesome.css'
import hp1 from '../../../static/img/home-page/1-EDS.3.0.jpg'
// Global styles and component-specific styles.
import './fonts.css'
import './global.css'
import styles from './main.module.css'
const urlPath = process.env.GATSBY_APPURL

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '', errorInfo: '' };
  }
  componentDidMount() {

    let aerosmith = `%c
      ___________                  _____________                  ___________\r\n     (___        \\________       //     /^\\     \\\\       ________/        ___)\r\n        (____             \\_____//     //^\\\\     \\\\_____/             ____)\r\n            (____              //\\__  // * \\\\  __/\\\\              ____)\r\n                 (__           ||   \\//_____\\\\/   ||           __)\r\n                    (__        ||   //       \\\\   ||       ___)\r\n                       (__     \\\\  //         \\\\  //    ___)\r\n                          (____ \\\\//_AEROSMITH_\\\\// ____)\r\n                               (_\\\\_____________//_)\r\n                               /__\\_/\\_/\\/\\_/\\_/__\\
      \r\n\r\n                          >>> https://bit.ly/1eCrels <<<
    `
    console.log(aerosmith, 'color: #E31937; font-family: "Lucida Console", Monaco, monospace')
  }
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: error, errorInfo: info });
  }

  returnHome = () => {
    navigate('/')
  }

  render() {
    let wrapperClass = ''
    let title = 'Elevator Design Studio'
    let description = 'The Elevator Design Studio lets you select your elevator interior configuration, apply materials and finishes, complete the package with trim pieces and ceilings, and request budget pricing.'
    let twitterUsername = 'forms_surfaces'
    let ogimage = urlPath + hp1
    if(this.props.wrapperClass){
      wrapperClass = this.props.wrapperClass
    }
    library.add(
      fab,
      faCheckSquare,
      faCoffee,
      faEdit,
      faCopy,
      faStar,
      faFileInvoiceDollar,
      faCloudDownloadAlt,
      faSearch,
      faCheckCircle,
      faPlusCircle,
      faTrash)
    let error = null
    if(this.state.error){
      error = <ErrorMessage message={this.state.error} closeAction={this.returnHome} />
    }
    return (
      <div className={wrapperClass}>
        <Helmet title={config.siteMetadata.title}>
          <meta name="description" content={description} />
          <meta name="image" content={ogimage} />
          <meta property="og:url" content='https://elevatordesignstudio.com' />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={ogimage} />
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="1597138230582666" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={twitterUsername} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={ogimage} />
        </Helmet>
        <Header wrapperClass={this.props.wrapperClass} />
        <main className={styles.main}>{this.props.children}</main>
        <Footer />
        {error}
      </div>
    )
  }
}

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})

export default Layout
