import { Auth } from 'aws-amplify'
import { isBrowser } from './misc'
import ObjectID from 'bson-objectid'
import { getUserInfo } from './user'
const getUser = () => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.gatsbyUser ? JSON.parse(localStorage.gatsbyUser) : {}
  } else {
    return null
  }
}

const setUser = (user) => {
  localStorage.gatsbyUser = JSON.stringify(user)
}
const setProfileImage = (image) => {
  localStorage.profileImage = image
}
export const handleLogin = ({ username, password }, callback) => {
  if (!isBrowser) return false
  Auth.authenticationFlowType = 'USER_PASSWORD_AUTH'
  Auth.signIn(username, password)
    .then(function(user) {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        var url = '/forgot?email=' + username
        setUser(user)
        setProfileImage('')
        window.location.assign(url)
        return callback({ message: 'Password Reset Required' })
      }
      getUserInfo()
      callback(null)
    })
    .catch(function(err) {
      callback(err)
    })
}

export const isFSUser = () => {
  if (!isBrowser) return false
  const user = getUser()
  if (user.email) {
    var domain = user.email.replace(/.*@/, '')
    if (domain === 'forms-surfaces.com') {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const redirectNotFSUser = () => {
  if (!isFSUser()) {
    console.log('you are no F+S user')
    return (window.location.href = '/')
  }
}

export const resetCodeSent = () => {
  if (!isBrowser) return false
  if (localStorage.resetCodeSent === 'true') {
    return true
  } else {
    return false
  }
}

export const changePassword = (oldPassword, newPassword, callback) => {
  if (!isBrowser) return false
  Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, oldPassword, newPassword)
    })
    .then(data => callback(null, data))
    .catch(err => callback(err))
}

export const getUserAttributes = (callback) => {
  if (!isBrowser) return false
  Auth.currentAuthenticatedUser()
    .then(user => { return user })
    .then(data => callback(null, data))
    .catch(err => callback(err))
}

export const updateUserAttributes = (attributes, callback) => {
  if (!isBrowser) return false
  Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.updateUserAttributes(user, attributes)
    })
    .then(data => callback(null, data))
    .catch(err => callback(err))
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return
  setUser(null)
  setProfileImage('')
  Auth.signOut()
    .then(data => {
      callback()
    })
    .catch(err => console.log(err));
}

export const handleForgot = ({ username }, callback) => {
  if (!isBrowser) return false

  Auth.forgotPassword(username)
    .then(function(data) {
      localStorage.resetCodeSent = true
      callback(null)
    })
    .catch(function(err) {
      callback(err)
    })
}

export const handleReset = ({ username, resetCode, password }, callback) => {
  if (!isBrowser) return false
  Auth.forgotPasswordSubmit(username, resetCode, password)
    .then(function(data) {
      callback(null)
    })
    .catch(function(err) {
      callback(err)
    })
}

export const handleRegister = (
  { username, password, passwordConfirm },
  callback
) => {
  if (!isBrowser) return false
  if (password !== passwordConfirm) {
    var err = new Error('Passwords must match.')
    return callback(err)
  }
  username = username.toLowerCase()
  Auth.signUp({
    username,
    password,
    attributes: {
      'custom:userId': ObjectID(),
    },
  })
    .then(function(data) {
      localStorage.confirmSent = true
      callback(null)
    })
    .catch(function(err) {
      callback(err)
    })
}

export const handleRegisterConfirm = ({ username, code }, callback) => {
  Auth.confirmSignUp(username, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true,
  })
    .then(data => callback(null, data))
    .catch(err => callback(err))
}

export const isLoggedIn = () => {
  // return Auth.currentAuthenticatedUser()
  return new Promise((resolve, reject) => {
    try{
      if (!isBrowser) reject(false)
      Auth.currentAuthenticatedUser()
      .then(function(user){
        resolve(user)
      })
      .catch(function(err){
        reject(err)
      })
    }catch(err){
      reject(err)
    }
  })
}

export const isAuthUser = async () => {
  await Auth.currentAuthenticatedUser()
  .then((data) => { return true; })
  .catch(() => { return false; });
}

export const getCurrentAuthUser = () => {
 return new Promise((resolve, reject) => {
  Auth.currentAuthenticatedUser()
   .then(user => {
    if (user.username) {
     resolve(user)
    } else {
     resolve(null)
    }
   })
   .catch(err => {
    resolve(null)
   });
 })
}
